<template>
  <common-dialog ref="dialog" :trigger="save" title="Metadata">
    <template v-slot:header>
      <v-menu close-on-click left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="menu-button"
            icon
            v-bind="attrs"
            v-on="on"
            :disabled="!availableFields.length"
          >
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(field, fieldIndex) in availableFields"
            :key="fieldIndex"
            @click="addField(field)"
          >
            <v-list-item-title>{{ field.label }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <div v-if="!fields.length">There is no meta for this file</div>
    <div
      class="row"
      v-else
      v-for="(field, fieldIndex) in fields"
      :key="`${field.sort}-${fieldIndex}`"
    >
      <v-text-field
        v-model="field.value"
        :label="field.label"
        placeholder=" "
        persistent-placeholder
        hide-details
      />
      <v-btn icon @click="delField(fieldIndex)">
        <v-icon>mdi-minus-circle-outline</v-icon>
      </v-btn>
    </div>
  </common-dialog>
</template>

<script>
import { mapActions } from 'vuex'

import CommonDialog from './CommonDialog'

export default {
  name: 'MetadataDialog',
  components: { CommonDialog },
  props: {
    item: Object
  },
  data: () => ({
    availableFields: [],
    fields: []
  }),
  methods: {
    ...mapActions('drive', ['driveSetMeta']),

    open() {
      const availableFields = [
        { label: 'Title', name: 'title', sort: 0 },
        { label: 'Composer', name: 'composer', sort: 1 },
        { label: 'Lyricist', name: 'lyricist', sort: 2 },
        { label: 'Performer', name: 'performer', sort: 3 },
        { label: 'Languages', name: 'lang', sort: 4 },
        { label: 'Year', name: 'year', sort: 5 },
        { label: 'Genre', name: 'genre', sort: 6 },
        { label: 'Album', name: 'album', sort: 7 },
        { label: 'Tags', name: 'tags', sort: 7 },
        { label: 'Copyright', name: 'copyright', sort: 8 }
      ]
      const fields = []
      for (const metaName in this.item.meta) {
        if (metaName === 'raw') continue
        const metaIndex = availableFields.findIndex((m) => m.name === metaName)
        if (metaIndex === -1) continue
        const meta = availableFields[metaIndex]
        availableFields.splice(metaIndex, 1)
        fields.push({ ...meta, value: this.item.meta.getRawValue(metaName) })
      }
      this.fields = fields.sort((a, b) => a.sort - b.sort)
      this.availableFields = availableFields
      this.$refs.dialog.open()
    },

    addField(field) {
      const metaName = field.name
      const index = this.availableFields.findIndex((m) => m.name === metaName)
      const value = this.item.meta.getRawValue(metaName) || ''
      field = { ...field, value }
      this.fields.push(field)
      this.fields.sort((a, b) => a.sort - b.sort)
      this.availableFields.splice(index, 1)
    },

    delField(index) {
      const field = this.fields[index]
      this.fields.splice(index, 1)
      this.availableFields.push({
        label: field.label,
        name: field.name,
        sort: field.sort
      })
      this.availableFields.sort((a, b) => a.sort - b.sort)
    },

    async save() {
      const id = this.item.id
      const meta = {}
      this.fields.forEach((field) => {
        if (field.value) meta[field.name] = field.value
      })
      await this.driveSetMeta({ id, meta })
    }
  }
}
</script>

<style scoped>
.row:not(:last-child) {
  margin-bottom: var(--app-gutter-md);
}
.row {
  display: flex;
  align-items: baseline;
  margin: 0;
}
.row > *:first-child {
  flex: 1;
}
</style>
