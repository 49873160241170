<template>
  <common-dialog
    ref="dialog"
    :trigger="driveRename"
    :args="{ name, id: item.id }"
    :disabled="!valid"
    :title="title"
    ok="Rename"
  >
    <v-form ref="form" lazy-validation v-model="valid">
      <v-text-field label="Name" required v-model="name" :rules="nameRules">
      </v-text-field>
    </v-form>
  </common-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import CommonDialog from './CommonDialog'

export default {
  name: 'RenameDialog',
  props: {
    item: Object
  },
  data: () => ({
    valid: false,
    name: null,
    nameRules: [(v) => !!v || 'File name is required'],
    error: null
  }),
  computed: {
    title() {
      return `Rename ${this.item?.isFolder ? 'folder' : 'file'}`
    }
  },
  watch: {
    item: {
      handler: function (value) {
        this.name = value?.name
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('drive', ['driveRename']),

    open: function () {
      this.$refs.dialog.open()
    }
  },

  components: {
    CommonDialog
  }
}
</script>
