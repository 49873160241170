<template>
  <v-list class="pa-0" dense>
    <v-divider />

    <button-menu-item prepend-icon="rename-box" label="Rename">
      <rename-dialog :item="item" />
    </button-menu-item>

    <button-menu-item prepend-icon="delete-outline" label="Delete">
      <delete-dialog :item="item" />
    </button-menu-item>

    <button-menu-item prepend-icon="tag-text-outline" label="Metadatas">
      <meta-dialog :item="item" />
    </button-menu-item>
  </v-list>
</template>
<script>
import ButtonMenuItem from '../menu-item/ButtonMenuItem'

import RenameDialog from '../dialog/RenameDialog.vue'
import DeleteDialog from '../dialog/DeleteDialog.vue'
import MetaDialog from '../dialog/MetaDialog.vue'

export default {
  name: 'ScoreMenu',
  components: {
    RenameDialog,
    DeleteDialog,
    MetaDialog,
    ButtonMenuItem
  },
  props: { item: Object }
}
</script>
<style scoped></style>
