<template>
  <common-dialog
    ref="dialog"
    :trigger="proceed"
    :notif="notif"
    :title="title"
    :message="message"
    message-type="error"
  >
  </common-dialog>
</template>

<script>
import { mapActions } from 'vuex'

import CommonDialog from './CommonDialog'

export default {
  name: 'DriveClearDialog',
  components: {
    CommonDialog
  },
  props: {
    item: Object
  },
  computed: {
    title() {
      return `Delete ${this.item?.isFolder ? 'folder' : 'file'}`
    },

    notif() {
      const item = this.item || {}
      return `The ${item.type || 'score'} '${item.name}' has been deleted`
    },

    message() {
      const item = this.item || {}
      return `You are about to delete '${item.name}'. This operation is not reversible. Do you confirm ?`
    }
  },
  methods: {
    ...mapActions('drive', ['del']),

    open: function () {
      this.$refs.dialog.open()
    },

    proceed: async function () {
      await this.del(this.item)
      if (this.$route.params.scoreId === this.item.id) {
        //
        // When we delete a score from score view
        //
        const parent = this.$store.getters['drive/current']
        if (parent.isLocalRoot) {
          this.$router.push(`/drive/${parent.id}`)
        } else {
          this.$router.push(`/drive/${parent.path}`)
        }
      }
    }
  }
}
</script>
